import { baseApi } from '../base'
import { LogInRequest, LogInResponse } from './types'

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    logIn: builder.mutation<LogInResponse, LogInRequest>({
      query: (credentials) => ({
        url: 'accounts/login/',
        method: 'POST',
        body: credentials,
      }),
    }),

    ping: builder.mutation<void, void>({
      query: () => ({ url: 'accounts/?limit=1', method: 'GET' }),
    }),
  }),
})

export const { useLogInMutation, usePingMutation } = authApi
