import {
  BlockContent,
  MultipartUploadStartResponse,
  PageData,
  MultipartUploadStartRequest,
  MultipartUploadFinishRequest,
} from 'types/manager'
import { baseApi } from '../base'
import { UploadImageResponse } from '../../../hooks/useBlockItemImageUpload'

export const managerApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPages: builder.query<PageData[], void>({
      query: () => `website-management/pages/`,
      providesTags: ['Pages'],
    }),
    getPageContent: builder.query<BlockContent[], string>({
      query: (id) => `website-management/pages/blocks/?page=${id}`,
      providesTags: ['BlockContent'],
    }),
    updateBlockContent: builder.mutation<BlockContent[], Partial<BlockContent> & Pick<BlockContent, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `website-management/pages/blocks/${id}/update/`,
        method: 'PATCH',
        body: JSON.stringify(patch),
      }),
      invalidatesTags: ['BlockContent', 'Pages'],
    }),
    createBlockItem: builder.mutation<any, any>({
      query({ id, ...payload }) {
        return {
          url: `website-management/pages/blocks/${id}/block-item/create/`,
          method: 'POST',
          body: payload,
        }
      },
    }),

    updateImage: builder.mutation<UploadImageResponse, { id: string; file: File }>({
      query({ id, file }) {
        const body = new FormData()
        body.set('thumbnail', file)

        return {
          url: `website-management/pages/blocks/block-item/media/${id}/update/`,
          headers: { ['Content-Type']: 'undefined' },
          method: 'PATCH',
          body,
        }
      },
    }),

    uploadImage: builder.mutation<UploadImageResponse, File>({
      query(file) {
        const body = new FormData()
        body.set('thumbnail', file)
        body.set('title', file.name)

        return {
          url: 'website-management/pages/blocks/block-item/media/upload/',
          headers: { ['Content-Type']: 'undefined' },
          method: 'POST',
          body,
        }
      },
    }),

    multipartUploadStart: builder.mutation<MultipartUploadStartResponse, MultipartUploadStartRequest>({
      query({ ...payload }) {
        return {
          url: `website-management/pages/blocks/block-item/media/multipart-upload/start/`,
          method: 'POST',
          body: payload,
        }
      },
    }),

    multipartUploadFinish: builder.mutation<{ url: string }, MultipartUploadFinishRequest>({
      query({ blockItemMediaId, payload }) {
        return {
          url: `website-management/pages/blocks/block-items/block-item/media/${blockItemMediaId}/multipart-upload/finish/`,
          method: 'POST',
          body: JSON.stringify(payload),
        }
      },
    }),

    updateBlockImage: builder.mutation<UploadImageResponse, { file: File; blockId: string }>({
      query({ file, blockId }) {
        const body = new FormData()
        body.set('image', file)

        return {
          url: `website-management/pages/blocks/${blockId}/update-image/`,
          headers: { ['Content-Type']: 'undefined' },
          method: 'PATCH',
          body,
        }
      },
    }),
  }),
})

export const {
  useGetPagesQuery,
  useGetPageContentQuery,
  useUpdateBlockContentMutation,
  useMultipartUploadStartMutation,
  useMultipartUploadFinishMutation,

  useUploadImageMutation,
  useUpdateImageMutation,
  useUpdateBlockImageMutation,
} = managerApi
