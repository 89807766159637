import { usePingMutation } from '../store/services/auth'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectAccessToken } from '../store/slices/user'

// To guarantee user won`t be logout by token expires. But will be logout on 'onIdle' event

let intId: null | any = null

export const useKeepTokenAlive = () => {
  const accessToken = useSelector(selectAccessToken)

  const [makePing] = usePingMutation()

  useEffect(() => {
    clearInterval(intId)

    if (accessToken) {
      const time = (Number(process.env.REACT_APP_REFRESH_TOKEN_EXPIRES_TIME || 10) - 2) * 60 * 1000

      intId = setInterval(() => accessToken && makePing(), time)
    }
  }, [makePing, accessToken])
}
