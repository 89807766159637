import { Backdrop, Box, CircularProgress } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { FC } from 'react'

interface IProps {
  isBackdrop?: boolean
  color?: string
  background?: string
}
const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
)

const Spinner: FC<IProps> = ({ isBackdrop, color = '#fff', background }) => {
  const { wrapper } = useStyles()

  if (isBackdrop) {
    return (
      <Backdrop sx={{ color, zIndex: (theme) => theme.zIndex.drawer + 1, background }} open>
        <CircularProgress color='inherit' />
      </Backdrop>
    )
  }

  return (
    <Box className={wrapper}>
      <CircularProgress color='inherit' />
    </Box>
  )
}

export default Spinner
