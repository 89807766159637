import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'

export interface UserState {
  accessToken: string | null
  refreshToken: string | null
  email: string | null
}

const initialState: UserState = {
  accessToken: null,
  refreshToken: null,
  email: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTokens: (state, { payload: { accessToken, refreshToken } }: PayloadAction<Partial<UserState>>) => {
      if (accessToken !== undefined) state.accessToken = accessToken
      if (refreshToken !== undefined) state.refreshToken = refreshToken
    },

    setAdminEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload
    },

    logout: (state) => {
      state.accessToken = null
      state.refreshToken = null
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTokens, setAdminEmail, logout } = userSlice.actions

export const selectAccessToken = (state: RootState) => state.user.accessToken
export const selectAdminEmail = (state: RootState) => state.user.email

export default userSlice.reducer
