const Routes = {
  DASHBOARD: '/dashboard',
  SIGN_IN: '/sign-in',
}

export enum DashboardRoutes {
  MANAGEMENT_PANEL = 'management-panel',
  EVIDENCE = 'evidence',
  USERS = 'users',
  ANALYTICS = 'analytics',
  PRICING = 'pricing',
  ORGANIZATIONS = 'organizations',
  ACCESSIBILITY = 'accessibility',
  PAYMENTS = 'payments',
  ACTIVITIES = 'activities',
  GUIDED_RECORDING_MANAGEMENT = 'guided-recording-management',
  APP_MANAGEMENT = 'app-management',
  PROMO_CODES = 'promo-codes',
  CREATE_ARTICLE = 'create-article',
  EDIT_ARTICLE = 'edit-article',
}

export enum WebsiteManagementRoutes {
  HEADER = 'website-management/header',
  MAIN = 'website-management/main',
  WELCOME = 'website-management/welcome',
  OUR_TEAM = 'website-management/team',
  OUR_PARTNERS = 'website-management/partners',
  JOBS = 'website-management/jobs',
  CSR = 'website-management/csr',
  FAQ = 'website-management/faq',
  TECH_SUPPORT = 'website-management/tech-support',
  TERMS_OF_USE = 'website-management/terms-of-use',
  PRIVACY_POLICY = 'website-management/privacy-policy',
  FOOTER = 'website-management/footer',
  BLOG = 'website-management/blog/',
}

export default Routes
