export const getParams = (params) =>
  Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((v) => `${key}=${v}`)
      }

      return `${key}=${value}`
    })
    .flat(1)
    .join('&')
