import { PaginationResult } from '../../../types/common/pagination-result'
import { IEvidence, ISingleEvidence } from '../../../types/evidence'
import { keysToCamel, keysToSnake } from '../../../modules/common/helpers/snakeToCamel'
import {
  DeleteEvidencesRequest,
  EvidenceFolder,
  EvidenceStatistic,
  ExtendEvidencesRequest,
  GetEvidenceParams,
} from './types'
import { baseApi } from '../base'
import { getParams } from '../../../modules/common/helpers/getParams'

export const evidenceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getEvidence: builder.query<PaginationResult<IEvidence>, GetEvidenceParams>({
      query: (params) => ({
        url: `evidence/?${getParams(params)}`,
      }),
      transformResponse: (res) => keysToCamel(res),
      providesTags: ['Evidences'],
    }),

    getUserEvidence: builder.query<PaginationResult<IEvidence>, GetEvidenceParams & { user_id: string }>({
      query: ({ user_id, ...params }) => ({
        url: `evidence/${user_id}/list/?${getParams(params)}`,
      }),
      transformResponse: (res) => keysToCamel(res),
      providesTags: ['Evidences'],
    }),

    getSingleEvidence: builder.query<ISingleEvidence, string>({
      query: (id) => ({ url: `evidence/${id}/` }),
      transformResponse: (res) => keysToCamel(res),
      providesTags: ['Evidences'],
    }),

    getEvidenceStatistic: builder.query<EvidenceStatistic, void>({
      query: () => ({ url: `evidence/calculate/` }),
      transformResponse: (res) => keysToCamel(res),
    }),

    getEvidenceUserFolders: builder.query<PaginationResult<EvidenceFolder>, { user_id: string } & any>({
      query: ({ user_id, ...params }) => ({
        url: `evidence/folders/accounts/${user_id}/?${getParams(params)}`,
      }),
      transformResponse: (res) => keysToCamel(res),
      providesTags: ['Evidences'],
    }),

    getEvidenceUserFolderProofs: builder.query<
      PaginationResult<EvidenceFolder>,
      { folder_id: string; user_id: string } & any
    >({
      query: ({ folder_id, user_id, ...params }) => ({
        url: `evidence/folders/${folder_id}/accounts/${user_id}/proofs/?${getParams(params)}`,
      }),
      transformResponse: (res) => keysToCamel(res),
      providesTags: ['Evidences'],
    }),

    deleteEvidences: builder.mutation<void, DeleteEvidencesRequest>({
      query: (payload) => ({
        url: 'evidence/proofs/delete/',
        method: 'POST',
        body: keysToSnake(payload),
      }),
      invalidatesTags: ['Evidences'],
    }),

    extendEvidence: builder.mutation<void, ExtendEvidencesRequest>({
      query: (payload) => ({
        url: 'evidence/proofs/extend/',
        method: 'POST',
        body: keysToSnake(payload),
      }),
      invalidatesTags: ['Evidences'],
    }),

    recoverEvidences: builder.mutation<void, Pick<ExtendEvidencesRequest, 'evidencesIdList'>>({
      query: (payload) => ({
        url: 'evidence/proofs/recover/',
        method: 'POST',
        body: keysToSnake(payload),
      }),
      invalidatesTags: ['Evidences'],
    }),
  }),
})

export const {
  useGetEvidenceQuery,
  useGetUserEvidenceQuery,
  useGetSingleEvidenceQuery,
  useGetEvidenceStatisticQuery,
  useDeleteEvidencesMutation,
  useExtendEvidenceMutation,
  useRecoverEvidencesMutation,
  useGetEvidenceUserFoldersQuery,
  useGetEvidenceUserFolderProofsQuery,
} = evidenceApi
