const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

export const toSnakeCase = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

const isArray = (a) => {
  return Array.isArray(a)
}

const isObject = (o) => {
  return o === Object(o) && !isArray(o) && typeof o !== 'function'
}

export const keysToSnake = (object: any) => {
  const getTransformedKey = (key) => toSnakeCase(key)

  return Object.keys(object).reduce((acc, key) => {
    acc[getTransformedKey(key)] = object[key]
    return acc
  }, {} as any)
}

export const keysToCamel = (o) => {
  if (isObject(o)) {
    const n = {}

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i)
    })
  }

  return o
}

export const snakeToHuman = (s) => {
  return s
    .match(/[A-Za-z][a-z]*/g)
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ')
}
