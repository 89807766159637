import { configureStore, Middleware, MiddlewareAPI, isRejectedWithValue, AnyAction } from '@reduxjs/toolkit'
import userReducer, { UserState } from './slices/user'
import uiReducer, { snackbarOpen } from './slices/ui'
import tableHeadersReducer, { TableHeadersState } from './slices/tableHeaders'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { setupListeners } from '@reduxjs/toolkit/query'
import { managerApi } from './services/manager/manager'
import { persistReducer, persistStore } from 'redux-persist'
import { evidenceApi } from './services/evidence'
import { baseApi } from './services/base'
import { usersApi } from './services/users'
import storage from 'redux-persist/lib/storage'
import { Reducer } from 'react'

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const statusCode = Math.floor(action.payload.originalStatus / 100)

    if (statusCode === 5) {
      api.dispatch(
        snackbarOpen({
          type: 'error',
          message: 'Server error. Please try again later ',
        }),
      )
    }
  }

  return next(action)
}

const getPersistConfig = (key: string) => ({ key, storage })

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    tableHeaders: persistReducer(getPersistConfig('tableHeaders'), tableHeadersReducer) as Reducer<
      TableHeadersState & any,
      AnyAction
    >,
    user: persistReducer(getPersistConfig('user'), userReducer) as Reducer<UserState & any, AnyAction>,
    // Add the generated reducer as a specific top-level slice
    [managerApi.reducerPath]: managerApi.reducer,
    [evidenceApi.reducerPath]: evidenceApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware).concat(rtkQueryErrorLogger),
  devTools: process.env.NODE_ENV !== 'production',
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
