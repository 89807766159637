import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HeaderStoredType } from 'hooks/useTableHeaderContext'
import { RootState } from 'store'

export interface TableHeadersState {
  activeHeaderColumns: HeaderStoredType[]
}

const initialState: TableHeadersState = {
  activeHeaderColumns: [],
}

export const tableHeadersSlice = createSlice({
  name: 'tableHeaders',
  initialState,
  reducers: {
    setActiveHeaderColumns: (state, { payload }: PayloadAction<HeaderStoredType[]>) => {
      state.activeHeaderColumns = payload
    },
    setHeaderOrdering: (
      state,
      { payload: { id, ordering } }: PayloadAction<Pick<HeaderStoredType, 'id' | 'ordering'>>,
    ) => {
      state.activeHeaderColumns = state.activeHeaderColumns.map((header) =>
        header.id === id ? { ...header, ordering } : header,
      )
    },
  },
})

// Action creators are generated for each case reducer function
export const { setActiveHeaderColumns, setHeaderOrdering } = tableHeadersSlice.actions

export const selectHeaderColumns = (state: RootState) => state.tableHeaders.activeHeaderColumns

export default tableHeadersSlice.reducer
